<template>
	<div class="main-content">
		<ktv-form label="Loan Package Add Basic Data Product Add Quantity" @submit="save">
			<template #content="{ invalid }">
				<ktv-modal v-model="is.showingModal" no-close-on-backdrop no-close-on-esc :title="$t('Add Loan Product', 'Add Loan Product')" @close="close()">
					<template #content>
						<ktv-image-viewer
							v-if="localProduct.ProductImages"
							:file-name="getProductImage(localProduct.ProductImages, 'filename')"
							:image="getProductImage(localProduct.ProductImages, 'path')"
							class="mb-24"
							thumbnail-class="w-75"
						/>
						<ktv-input
							v-model="localProduct.quantity"
							:label="$t('Quantity', 'Quantity')"
							:max="localProduct.Stock"
							min="0"
							rules="required"
							type="number"
						/>
						<ktv-input v-model="localProduct.Name" :label="$t('Product', 'Product')" readonly />
						<ktv-input v-model="localProduct.ProductID" :label="$t('ID', 'ID')" readonly />
						<ktv-input v-model="localProduct.ProductUnitName" :label="$t('Unit Type', 'Unit Type')" readonly />
						<ktv-input v-model="localProduct.SalePrice" :label="$t('Price/unit', 'Price/unit')" readonly />
						<ktv-input v-model="localProduct.totalPrice" :label="$t('Total', 'Total')" readonly />
					</template>
					<template #footer>
						<div align="right">
							<ktv-button
								:text="$t('Save', 'Save')"
								:loading="is.loading"
								:disabled="invalid || localProduct.quantity <= 0"
								icon="save"
								@click="save()"
							/>
							<ktv-button :text="$t('Close', 'Close')" color="light" :loading="is.loading" class="ml-2" @click="close()" />
						</div>
					</template>
				</ktv-modal>
			</template>
		</ktv-form>
	</div>
</template>
<script>
	import { KtvButton, KtvForm, KtvImageViewer, KtvInput, KtvModal } from "@/components"

	export default {
		name: "LoanPackageAddBasicDataProductAddQuantity",
		metaInfo: {
			title: "Package Add - Basic Data - Add Product",
		},
		components: { KtvButton, KtvForm, KtvImageViewer, KtvInput, KtvModal },
		props: {
			product: {
				default: () => {},
				required: false,
				type: Object,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-save"],
		data() {
			return {
				is: {
					loading: false,
					showingModal: false,
				},
				localProduct: null,
			}
		},
		computed: {
			totalPrice() {
				return this.localProduct.SalePrice * this.localProduct.quantity
			},
		},
		watch: {
			product: {
				deep: true,
				handler(product) {
					this.localProduct = Object.assign(
						{
							quantity: 0,
						},
						product,
					)
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		methods: {
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			getProductImage(imagesInString, type = "path") {
				const imagesArray = imagesInString.split(",")

				if (type === "filename") {
					return imagesArray[0] ? imagesArray[0] : null
				}

				return imagesArray[0] ? `${process.env.VUE_APP_URL_FR_PRODUCT_IMAGE}${imagesArray[0]}` : null
			},
			save() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.$emit("on-save", {
					...this.localProduct,
					totalPrice: this.totalPrice,
				})
				this.$emit("close")
			},
		},
	}
</script>
