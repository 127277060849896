<template>
	<b-form v-if="localPackage">
		<basic-data-product-add-modal :kios-k-id="localPackage.kioskId" :show="is.showing.addModal" @close="closeAddModal" @on-save="addProduct" />
		<basic-data-product-add-quantity-modal
			:product="updateProduct"
			:show="is.showing.updateModal"
			@close="closeUpdateModal"
			@on-save="updatePackageProduct"
		/>
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this product?', 'Do you want to delete this product?')"
			:title="$t('Delete Product', 'Delete Product')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deletePackageProduct()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<ktv-form label="Form Add Package Basic Data" @submit="save">
			<template #content="{ invalid }">
				<ktv-accordion name="loanPackageInformation" :title="$t('Loan Package Information', 'Loan Package Information')">
					<template #content>
						<b-row v-if="$route.params.id">
							<ktv-input v-model="localPackage.loanPackageId" rules="required" :label="$t('ID', 'ID')" readonly />
						</b-row>
						<b-row>
							<ktv-input v-model="localPackage.packageName" rules="required" :label="$t('Package Name', 'Package Name')" />
						</b-row>
						<b-row>
							<ktv-button-group
								v-model="localPackage.loanCostumerTypeId"
								:label="$t('Customer Type', 'Customer Type')"
								:options="customerTypeOptions"
								rules="required"
								@on-click="changeCustomerType"
							/>
						</b-row>
						<b-row>
							<ktv-button-group
								v-model="localPackage.loanPackageTypeId"
								:label="$t('Package Type', 'Package Type')"
								:options="packageTypeOptions"
								rules="required"
								@on-click="changePackageType"
							/>
						</b-row>
						<b-row>
							<ktv-checkbox-group
								v-model="localPackage.documentRequired"
								:label="$t('Required Documents', 'Required Documents')"
								:options="documentTypeOptions"
								rules="required"
								@on-change="(value) => (localPackage.documentRequired = value)"
							/>
						</b-row>
						<b-row>
							<b-col class="px-0">
								<ktv-input v-model="localPackage.margin" rules="required" :label="$t('Margin', 'Margin')" type="number" min="0" append="%" />
							</b-col>
							<b-col class="px-0">
								<ktv-input
									v-model="localPackage.provision"
									:label="$t('Provision', 'Provision')"
									append="%"
									min="0"
									rules="required"
									type="number"
								/>
							</b-col>
							<b-col class="px-0">
								<ktv-select v-model="localPackage.currencyId" :options="currencyOptions" rules="required" :label="$t('Currency', 'Currency')" />
							</b-col>
						</b-row>
						<b-row>
							<b-col class="px-0">
								<ktv-select v-model="localPackage.countryId" :options="countryOptions" rules="required" :label="$t('Country', 'Country')" />
							</b-col>
							<b-col class="px-0">
								<ktv-select
									v-model="localPackage.provinceId"
									:disabled="!localPackage.countryId"
									:options="provinceOptions"
									rules="required"
									:label="$t('Province', 'Province')"
								/>
							</b-col>
							<b-col class="px-0">
								<ktv-select
									v-model="localPackage.districtId"
									:disabled="!localPackage.provinceId"
									:label="$t('District', 'District')"
									:options="districtOptions"
									rules="required"
								/>
							</b-col>
						</b-row>
					</template>
				</ktv-accordion>
				<ktv-accordion
					v-if="localPackage.loanPackageTypeId === 155 || localPackage.loanPackageTypeId === 157"
					:title="$t('Loan Cash', 'Loan Cash')"
					class="mt-4"
					name="loanPackageCash"
				>
					<template #content>
						<b-row>
							<ktv-input v-model="totalCashAmount" :label="$t('Cash Amount', 'Cash Amount')" rules="required" type="currency" />
						</b-row>
					</template>
				</ktv-accordion>
				<ktv-accordion
					v-if="localPackage.loanPackageTypeId === 156 || localPackage.loanPackageTypeId === 157"
					:title="$t('Loan Product', 'Loan Product')"
					class="mt-4"
					name="loanPackageProduct"
				>
					<template #content>
						<b-row>
							<b-col class="px-0">
								<ktv-select
									v-model="localPackage.kioskId"
									:disabled="!localPackage.districtId"
									:label="$t('Kiosk', 'Kiosk')"
									:options="kiosKOptions"
									rules="required"
									@input="onKiosKSelected"
								/>
							</b-col>
							<b-col class="px-0">
								<ktv-input v-model="totalProductAmountString" :label="$t('Product Amount', 'Product Amount')" readonly type="currency" />
							</b-col>
						</b-row>
						<b-form-group
							:invalid-feedback="$t('A minimum of one product is required.', 'A minimum of one product is required.')"
							:label="$t('Products*', 'Products*')"
							:state="isProductsNotEmpty"
						/>
						<ktv-table
							id="package-products"
							:columns="columns"
							:filter="false"
							:is-loading="is.loading"
							:rows="filteredData"
							:search-enabled="false"
							:search-on-enter="false"
							:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
							:total-rows="packageProducts ? filteredData.length : 0"
							min-height="250px"
							mode="client"
							@on-search="onSearch"
						>
							<template #actionleft>
								<ktv-button :text="$t('Add', 'Add')" icon="add" @click="showAddModal" />
							</template>
							<template #columns="{ props }">
								{{ $t(props.column.label, props.column.label) }}
							</template>
							<template #rows="{ props }">
								<span v-if="props.column.field === 'button'">
									<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
										<template #button-content>
											<img :src="icons.dropdown" />
										</template>
										<b-dropdown-item @click="showUpdateModal(props.row)">
											<img :src="icons.edit" class="imgicon" />{{ $t("Update", "Update") }}
										</b-dropdown-item>
										<b-dropdown-item @click="showDeleteDialog(props.row)">
											<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
										</b-dropdown-item>
									</b-dropdown>
								</span>
								<span v-else-if="props.column.field === 'ProductImages'">
									<span v-if="props.row.ProductImages">
										<ktv-image-viewer
											:file-name="getProductImage(props.row.ProductImages, 'filename')"
											:image="getProductImage(props.row.ProductImages, 'path')"
											thumbnail-class="w-75"
										/>
									</span>
									<span v-else>-</span>
								</span>
								<span v-else-if="props.column.field === 'SalePrice'">
									{{ props.row.SalePrice | Number }}
								</span>
								<span v-else-if="props.column.field === 'totalPrice'">
									{{ (parseInt(props.row.quantity, 10) * parseInt(props.row.SalePrice, 10)) | Number }}
								</span>
							</template>
						</ktv-table>
					</template>
				</ktv-accordion>
				<div class="bg-gray-100 p-4 mt-4" align="center">
					<ktv-button :loading="is.loading" :text="$t('Save', 'Save')" icon="save" :disabled="invalid" type="submit" />
				</div>
			</template>
		</ktv-form>
	</b-form>
</template>

<script>
	import { filter, findWhere, map, pluck, reduce } from "underscore"
	import {
		KtvAccor as KtvAccordion,
		KtvButton,
		KtvButtonGroup,
		KtvCheckboxGroup,
		KtvDialog,
		KtvForm,
		KtvImageViewer,
		KtvInput,
		KtvSelect,
		KtvTable,
	} from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"

	import BasicDataProductAddModal from "./modals/_BasicDataProductAdd"
	import BasicDataProductAddQuantityModal from "./modals/_BasicDataProductAddQuantity"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import editIcon from "@/assets/images/icon/iconedit.png"

	const icons = {
		delete: deleteIcon,
		dropdown: dropdownIcon,
		edit: editIcon,
	}

	export default {
		name: "LoanPackageAddBasicData",
		metaInfo: {
			title: "Package Add - Basic Data",
		},
		components: {
			BasicDataProductAddModal,
			BasicDataProductAddQuantityModal,
			KtvAccordion,
			KtvButton,
			KtvButtonGroup,
			KtvCheckboxGroup,
			KtvDialog,
			KtvForm,
			KtvImageViewer,
			KtvInput,
			KtvSelect,
			KtvTable,
		},
		props: {
			pkg: {
				default: () => {},
				required: false,
				type: Object,
			},
		},
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "ProductID",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "ProductImages",
						label: "Image",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "Name",
						label: "Product Name",
						thClass: "text-left",
					},
					{
						field: "ProductUnitName",
						label: "Unit Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "quantity",
						label: "Product Quantity",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "SalePrice",
						label: "Price",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "totalPrice",
						label: "Total",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
				],
				countryOptions: [],
				currencyOptions: [],
				deleteProductId: null,
				districtOptions: [],
				icons: icons,
				is: {
					loading: false,
					showing: {
						addModal: false,
						deleteDialog: false,
						updateModal: false,
					},
				},
				kiosKOptions: [],
				localPackage: {
					countryId: null,
					createdBy: null,
					currencyId: null,
					districtId: null,
					documentRequired: [],
					kioskId: null,
					kioskName: null,
					loanCostumerTypeId: null,
					loanInstitutionId: this.$route.params?.institutionId ? parseInt(this.$route.params.institutionId, 10) : null,
					loanPackageId: null,
					loanPackageTypeId: null,
					margin: null,
					packageName: null,
					provinceId: null,
					provision: null,
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				provinceOptions: [],
				searchText: null,
				totalCashAmount: null,
				updateProduct: null,
			}
		},
		computed: {
			...mapGetters({
				packageProducts: "PACKAGE/packageProducts",
				userId: "AUTHDATA/userId",
			}),
			...mapState("OTHERS", [
				"countryList",
				"currencyList",
				"customerTypeList",
				"districtList",
				"documentTypeList",
				"kiosKList",
				"packageTypeList",
				"provinceList",
			]),
			customerTypeOptions() {
				return map(this.customerTypeList, (customerType) => {
					return customerType && customerType.value === "sme"
						? {
							text: this.$t("SME", "SME"),
							value: parseInt(customerType.entityListOfValueId, 10),
						}
						: {
							text: this.$t(this.capitalize(customerType.value), this.capitalize(customerType.value)),
							value: parseInt(customerType.entityListOfValueId, 10),
						}
				})
			},
			documentTypeOptions() {
				return map(this.documentTypeList, (documentType) => {
					return documentType && documentType.value === "Marriage Certificae"
						? {
							text: this.$t("Marriage Certificate", "Marriage Certificate"),
							value: parseInt(documentType.entityListOfValueId, 10),
						}
						: {
							text: this.$t(this.capitalize(documentType.value), this.capitalize(documentType.value)),
							value: parseInt(documentType.entityListOfValueId, 10),
						}
				})
			},
			filteredData() {
				return this.searchText
					? filter(this.packageProducts, (product) => {
						const premiumName = String(product.Name).toLowerCase()
						const premiumProductId = String(product.ProductID).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return premiumName.indexOf(searchText) > -1 || premiumProductId.indexOf(searchText) > -1
					})
					: this.packageProducts
			},
			isProductsNotEmpty() {
				if (this.localPackage.loanPackageTypeId === 156 || this.localPackage.loanPackageTypeId === 157) {
					return this.packageProducts && this.packageProducts.length > 0
				}

				return true
			},
			packageTypeOptions() {
				return map(this.packageTypeList, (packageType) => {
					return {
						text: this.$t(packageType.value, packageType.value),
						value: parseInt(packageType.entityListOfValueId, 10),
					}
				})
			},
			totalAmount() {
				const totalCashAmount = this.totalCashAmount ? parseInt(String(this.totalCashAmount).replaceAll(",", ""), 10) : 0

				if (this.localPackage.loanPackageTypeId === 155) {
					return totalCashAmount
				}
				if (this.localPackage.loanPackageTypeId === 156) {
					return this.totalProductAmount
				}

				return parseInt(totalCashAmount + this.totalProductAmount, 10)
			},
			totalProductAmount() {
				const totalProductAmount = reduce(
					this.packageProducts,
					(total, product) => {
						return product.quantity * parseInt(product.SalePrice, 10) + total
					},
					0,
				)

				return totalProductAmount
			},
			totalProductAmountString() {
				return this.$options.filters.Number(this.totalProductAmount)
			},
		},
		watch: {
			countryList: {
				deep: true,
				handler() {
					this.getCountryOptions()
				},
				immediate: true,
			},
			currencyList: {
				deep: true,
				handler() {
					this.getCurrencyOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			kiosKList: {
				deep: true,
				handler() {
					this.getKiosKOptions()
				},
				immediate: true,
			},
			"localPackage.countryId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localPackage = {
									...this.localPackage,
									provinceId: null,
								}
							}

							const country = findWhere(this.countryList, { entityCountryID: String(this.localPackage.countryId) })

							if (country && country.countryCode) {
								this.getProvinceList(country.countryCode)
							}
						}
					} else if (!newValue && oldValue) {
						this.localPackage = {
							...this.localPackage,
							provinceId: null,
						}
					}
				},
				immediate: true,
			},
			"localPackage.districtId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localPackage = {
									...this.localPackage,
									kioskId: null,
								}
							}

							this.getKiosKList(this.localPackage.districtId)
						}
					} else if (!newValue && oldValue) {
						this.localPackage = {
							...this.localPackage,
							kioskId: null,
						}
					}
				},
				immediate: true,
			},
			"localPackage.kioskId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							this.resetProductList()

							if (this.$route.params.id && this.localPackage.product) {
								this.getProductList({
									kioskId: this.localPackage.kioskId,
									product: this.localPackage.product,
								})
							}
						}
					} else {
						this.resetProductList()
					}
				},
				immediate: true,
			},
			"localPackage.provinceId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localPackage = {
									...this.localPackage,
									districtId: null,
								}
							}

							this.getDistrictList(this.localPackage.provinceId)
						}
					} else if (!newValue && oldValue) {
						this.localPackage = {
							...this.localPackage,
							districtId: null,
						}
					}
				},
				immediate: true,
			},
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
			userId: {
				handler() {
					this.localPackage = {
						...this.localPackage,
						createdBy: this.userId ? parseInt(this.userId, 10) : null,
					}
				},
				immediate: true,
			},
		},
		created() {
			this.getCountryList()
			this.getCurrencyList()
			this.getCustomerTypeList()
			this.getDocumentTypeList()
			this.getPackageTypeList()
		},
		mounted() {
			if (this.pkg) {
				const requiredDocuments = pluck(this.pkg.documentRequired, "documentType")

				this.localPackage = {
					...this.localPackage,
					...this.pkg,
					documentRequired: requiredDocuments,
					loanPackageTypeId: this.pkg.loanPackageTypeId || 157,
				}

				this.totalCashAmount = this.pkg.totalCashAmount
			}
		},
		beforeDestroy() {
			this.reset()
		},
		methods: {
			...mapActions({
				addProduct: "PACKAGE/addProduct",
				deleteProduct: "PACKAGE/deleteProduct",
				createPackage: "PACKAGE/createPackage",
				getCountryList: "OTHERS/getCountryList",
				getCurrencyList: "OTHERS/getCurrencyList",
				getCustomerTypeList: "OTHERS/getCustomerTypeList",
				getDistrictList: "OTHERS/getDistrictList",
				getDocumentTypeList: "OTHERS/getDocumentTypeList",
				getKiosKList: "OTHERS/getKiosKList",
				getProductList: "PACKAGE/getProductList",
				getPackageTypeList: "OTHERS/getPackageTypeList",
				getProvinceList: "OTHERS/getProvinceList",
				resetPackage: "PACKAGE/resetPackage",
				resetProductList: "PACKAGE/resetProductList",
				updatePackage: "PACKAGE/updatePackage",
				updatePackageProduct: "PACKAGE/updateProduct",
			}),
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			changeCustomerType(type = null) {
				this.localPackage = {
					...this.localPackage,
					loanCostumerTypeId: type,
				}
			},
			changePackageType(type = null) {
				this.localPackage = {
					...this.localPackage,
					loanPackageTypeId: type,
				}
			},
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			closeDeleteDialog() {
				this.deleteProductId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			closeUpdateModal() {
				this.updateProduct = null

				this.is.showing = {
					...this.is.showing,
					updateModal: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			deletePackageProduct() {
				this.deleteProduct(this.deleteProductId)
					.then(() => {
						this.closeDeleteDialog()
					})
					.catch(() => {
						this.closeDeleteDialog()
					})
			},
			formatCashAmount() {
				this.totalCashAmount = this.$options.filters.Number(this.totalCashAmount)
			},
			getCountryOptions() {
				this.countryOptions = map(this.countryList, (country) => {
					return {
						text: country.country,
						value: parseInt(country.entityCountryID, 10),
					}
				})
			},
			getCurrencyOptions() {
				this.currencyOptions = map(this.currencyList, (currency) => {
					return {
						text: `${currency.isoCode} - ${currency.currencyName}`,
						value: parseInt(currency.id, 10),
					}
				})
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			getKiosKOptions() {
				this.kiosKOptions = map(this.kiosKList, (kiosK) => {
					return {
						text: kiosK.PersonName,
						value: parseInt(kiosK.EntityID, 10),
					}
				})
			},
			getProductImage(imagesInString, type = "path") {
				const imagesArray = imagesInString.split(",")

				if (type === "filename") {
					return imagesArray[0] ? imagesArray[0] : null
				}

				return imagesArray[0] ? `${process.env.VUE_APP_URL_FR_PRODUCT_IMAGE}${imagesArray[0]}` : null
			},
			getProvinceOptions() {
				this.provinceOptions = map(this.provinceList, (province) => {
					return {
						text: province.province,
						value: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			onKiosKSelected(value) {
				const kiosk = findWhere(this.kiosKOptions, { value: value })

				if (kiosk) {
					this.localPackage = {
						...this.localPackage,
						kioskName: kiosk.text,
					}
				}
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			reset() {
				this.resetPackage()
				this.resetProductList()
			},
			save() {
				this.is = {
					...this.is,
					loading: true,
				}

				let localPackage = Object.assign({}, this.localPackage)
				const requiredDocuments = map(localPackage.documentRequired, (document) => {
					return {
						documentType: document,
					}
				})
				const products = map(this.packageProducts, (product) => {
					return {
						productId: parseInt(product.ProductID, 10),
						quantity: parseInt(product.quantity, 10),
					}
				})

				localPackage = {
					...localPackage,
					documentRequired: requiredDocuments,
					margin: parseInt(localPackage.margin, 10),
					product: products,
					provision: parseInt(localPackage.provision, 10),
					totalAmount: this.totalAmount,
					totalCashAmount: this.totalCashAmount ? parseInt(String(this.totalCashAmount).replaceAll(",", ""), 10) : 0,
					totalProductAmount: this.totalProductAmount,
				}

				if (!this.localPackage.loanPackageId) {
					this.createPackage(localPackage)
						.then(() => {
							this.is = {
								...this.is,
								loading: false,
							}
						})
						.catch(() => {
							this.is = {
								...this.is,
								loading: false,
							}
						})
				} else {
					this.updatePackage(localPackage)
						.then(() => {
							this.is = {
								...this.is,
								loading: false,
							}
						})
						.catch(() => {
							this.is = {
								...this.is,
								loading: false,
							}
						})
				}
			},
			showAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			showDeleteDialog({ ProductID }) {
				this.deleteProductId = ProductID

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			showUpdateModal(product = null) {
				this.updateProduct = product

				this.is.showing = {
					...this.is.showing,
					updateModal: true,
				}
			},
		},
	}
</script>
