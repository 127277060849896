<template>
	<div class="main-content">
		<basic-data-product-add-quantity-modal :product="product" :show="is.showing.addModal" @close="closeAddModal" @on-save="save" />
		<ktv-modal
			v-model="is.showingModal"
			:title="$t('Add Loan Product', 'Add Loan Product')"
			no-close-on-backdrop
			no-close-on-esc
			size="lg"
			@close="close()"
		>
			<template #content>
				<ktv-table
					id="product-add"
					:columns="columns"
					:filter="false"
					:is-loading="is.loading"
					:line-numbers="false"
					:rows="filteredData"
					:search-enabled="false"
					:search-on-enter="false"
					:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
					:total-rows="productList ? filteredData.length : 0"
					min-height="250px"
					mode="client"
					@on-search="onSearch"
				>
					<template #columns="{ props }">
						{{ $t(props.column.label, props.column.label) }}
					</template>
					<template #rows="{ props }">
						<span v-if="props.column.field === 'ProductImages'">
							<span v-if="props.row.ProductImages">
								<ktv-image-viewer
									:file-name="getProductImage(props.row.ProductImages, 'filename')"
									:image="getProductImage(props.row.ProductImages, 'path')"
								/>
							</span>
							<span v-else>-</span>
						</span>
						<span v-else-if="props.column.field === 'SalePrice'">
							{{ props.row.SalePrice | Number }}
						</span>
						<span v-else-if="props.column.field === 'button'">
							<ktv-button
								:disabled="isAlreadyExist(props.row.ProductID)"
								:loading="is.loading"
								:text="$t('Choose', 'Choose')"
								size="sm"
								@click="chooseProduct(props.row)"
							/>
						</span>
					</template>
				</ktv-table>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Close', 'Close')" color="light" :loading="is.loading" class="ml-2" @click="close()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { filter, findWhere } from "underscore"
	import { KtvButton, KtvImageViewer, KtvModal, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"

	import BasicDataProductAddQuantityModal from "./_BasicDataProductAddQuantity"

	export default {
		name: "LoanPackageAddBasicDataProductAdd",
		metaInfo: {
			title: "Package Add - Basic Data - Add Product",
		},
		components: { BasicDataProductAddQuantityModal, KtvButton, KtvImageViewer, KtvModal, KtvTable },
		props: {
			kiosKId: {
				default: null,
				required: false,
				type: [Number, String],
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-save"],
		data() {
			return {
				columns: [
					{
						field: "ProductID",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "ProductImages",
						label: "Image",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "Name",
						label: "Product Name",
						thClass: "text-left",
					},
					{
						field: "ProductUnitName",
						label: "Unit Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "SalePrice",
						label: "Price",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "Stock",
						label: "Stock",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-right",
						thClass: "text-right",
						width: "100px",
					},
				],
				is: {
					loading: false,
					showing: {
						addModal: false,
					},
					showingModal: false,
				},
				product: null,
				searchText: null,
			}
		},
		computed: {
			...mapGetters({
				packageProducts: "PACKAGE/packageProducts",
			}),
			...mapState("OTHERS", ["productList"]),
			filteredData() {
				return this.searchText
					? filter(this.productList, (product) => {
						const name = String(product.Name).toLowerCase()
						const productID = String(product.ProductID).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return name.indexOf(searchText) > -1 || productID.indexOf(searchText) > -1
					})
					: this.productList
			},
		},
		watch: {
			kiosKId: {
				handler() {
					if (this.kiosKId) {
						this.getData()
					}
				},
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		methods: {
			...mapActions({
				getProductList: "OTHERS/getProductList",
			}),
			chooseProduct(product) {
				this.product = product

				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getProductList({
					kiosKId: this.kiosKId,
					params: this.params,
				})
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getProductImage(imagesInString, type = "path") {
				const imagesArray = imagesInString.split(",")

				if (type === "filename") {
					return imagesArray[0] ? imagesArray[0] : null
				}

				return imagesArray[0] ? `${process.env.VUE_APP_URL_FR_PRODUCT_IMAGE}${imagesArray[0]}` : null
			},
			isAlreadyExist(ProductID) {
				return !!findWhere(this.packageProducts, { ProductID: ProductID })
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			save(product) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.$emit("on-save", product)
				this.$emit("close")
			},
		},
	}
</script>
